.root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../assets/img/bg_login.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.box {
    background-color: #FFF;
    border-radius: 20px;
    height: auto;
    padding: 32px;
    width: 30vw;
    display: block;
    margin: auto;
    max-width: 488px;
}

.img {
    width: 130px;
}