.forgot {
    color: #4D0C82;
    cursor: pointer;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer p, span {
    font-size: 15px!important;
}

.title {
    font-weight: 500!important;
    font-size: 48px!important;
    line-height: 52.8px!important;
}

.link {
    text-decoration: none;
}

@media (max-width: 900px) {
    .box {
        width: 80%;
    }
}