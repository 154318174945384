@font-face {
  font-family: 'MinhaFontePersonalizada';
  src: url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6 {
  font-family: "MinhaFontePersonalizada"!important;
}