.backgroundIcon {
    margin-top: 20px!important;
    color: gray;
    display: block;
    margin: auto;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
}

.backgroundIconActive {
    margin-top: 20px!important;
    color: #FFF;
    background-color: #EA1A7F;
    border-radius: 5px;
    display: block;
    margin: auto;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
}

@media (max-width: 767px) {
    .backgroundIcon {
        margin-top: 0px!important;
        color: gray;
        cursor: pointer;
        padding-left: 8px;
        padding-right: 8px;
    }
    
    .backgroundIconActive {
        margin-top: 0px!important;
        color: #FFF;
        background-color: #EA1A7F;
        border-radius: 5px;
        cursor: pointer;
        padding-left: 8px;
        padding-right: 8px;
    }
}
