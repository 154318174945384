.boxTab {
    display: flex;
    align-items: center;
    background-color: #F2EDF5;
    padding: 12px 16px 12px 16px;
    border-radius: 32px;
}

.boxContent {
    background-color: #F2EDF5;
    padding: 12px 16px 12px 16px;
    border-radius: 32px;
}

.activeTab {
    background-color: #4D0B82;
    padding: 12px 16px 12px 16px;
    border-radius: 32px;
    color: #FFF;
    width: 50%;
    text-align: center;
    cursor: pointer;
    transition: 0.2s;
}

.inactiveTab {
    background-color: #F2EDF5;
    padding: 12px 16px 12px 16px;
    border-radius: 32px;
    color: #020814;
    width: 50%;
    text-align: center;
    cursor: pointer;
    transition: 0.2s;
}

@media (min-width: 701px) {
    .boxTab {
        width: 382px!important;
    }
}