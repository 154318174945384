iframe {
    height: 600px;
    width: 100%;
    border-radius: 15px;
    margin-top: 20px;
}

@media (max-width: 900px) {
    iframe {
        height: 200px;
    }
}